import React from 'react';
import * as styles from './SiteSection.module.scss'

function SiteSection(props) {
  return (
    <section {...props} className={`${styles.section} ${props.className}`}>
      {props.children}
    </section>
  );
}

export default SiteSection;