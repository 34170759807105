import React, {useEffect, useRef} from 'react';
import * as s from './CardSix.module.scss'
import {LazyVideo} from 'react-lazy-media'

const CardSix = ({data}) => {
  const video = useRef(null)

  const videoStartOnVision = (entries, cb) => {
    entries.forEach(entry => {
      entry.isIntersecting ? video.current.play() : video.current.pause()
    })
  }

  useEffect(()=>{
    const observer = new IntersectionObserver(videoStartOnVision, {threshold: 1.0})
    observer.observe(video.current)
  }, [])



  return (
    <div className={s.wrapper}>
      <div className={s.bgVideo}>
        <video preload="metadata" loop muted ref={video}>
          <source src={data.bgVideo} type="video/mp4" />
            Тег video не поддерживается вашим браузером.
            <a href={data.bgVideo}>Скачайте видео</a>.
        </video>
      </div>
      <div className={`${s.content} ${!data.black && s.white}`}>
        <span>
          {data.desc}
        </span>
        <h3>
          {data.name}
        </h3>
        <div className={s.linkWrapper}>
          <a href={data.siteLink}>
            Просмотреть сайт
          </a>
          {/*<a href={data.siteLink}>*/}
          {/*  Прочитать отзыв*/}
          {/*</a>*/}
        </div>
      </div>
    </div>
  );
};





export default CardSix;