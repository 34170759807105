import React from 'react';
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import {Col, Container, Row} from "../../Atoms/greeds";
import * as styles from './Cta.module.scss'
import Modal from "../../Molecules/Modal/Modal";
import StartWork from "../../Molecules/forms/StartWork";

function Cta(props) {
  return (
    <SiteSection>
      <Container>
        <div className={styles.wrapper}>

            <Col lg={7}>
              <Row className={styles.fCol}>
                <h2 className={styles.header}>
                  Нужна консультация?
                </h2>
                <p className={styles.desc}>
                  Оставьте заявку на бесплатную консультацию по разработке сайта. Мы свяжемся с вами в течение 30 минут и ответим на все ваши вопросы!
                </p>
                <Modal primary btnText="Получить консультацию" modalHeader="Получить консультацию">
                  <StartWork/>
                </Modal>
              </Row>
            </Col>
            <Col lg={5}>
              <Row>
                <img src="../../imgs/cta.svg" alt="" className={styles.img}/>
              </Row>
            </Col>

        </div>
      </Container>
    </SiteSection>
  );
}

export default Cta;