import React from 'react';
import * as styles from './CardOne.module.scss'
import {BadgeBig} from "../../Atoms/Badge";


function CardOne({Badge, Name, Desk, index}) {
    return (
        <div className={styles.wrapper}>
            <BadgeBig className={styles.budge}>{Badge}</BadgeBig>
            <h3 className={styles.header}>{Name}</h3>
            <p className={styles.desk}>{Desk}</p>
        </div>
    );
}

export default CardOne;