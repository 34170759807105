import React from 'react';
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import {Col, Container, Row} from "../../Atoms/greeds";
import SectionHeader from "../../Molecules/SectionHeader";

import * as styles from './Portfolio.module.scss'
import CardSix from "../../Molecules/CardSix/CardSix";


const data = [
  {
    name:"Business Media",
    desc:"Cайт производителя светодиодных экранов",
    img:"../../imgs/bmlife.jpg",
    bgVideo:'../../img/bmportfoliocover.mp4',
    siteLink:"https://bmlife.ru",
    black: true,
  },
  {
    name:"Art Flash Magazine",
    desc:"Сайт журнала о моде и искусстве",
    img:"../../imgs/artflash.jpg",
    bgVideo:'../../img/artflashportfolio.mp4',
    siteLink:"https://artflashmagazine.ru/",
    black: false,
  }
]

function Portfolio(props) {
  return (
    <SiteSection>
      <Container>
        <SectionHeader
          h2
          bigText="Наши лучшие работы"
        >Проекты студии</SectionHeader>
        <Row>
          {data.map((i,k) => <Col className={styles.portfolio} key={k}><CardSix data={i} key={k}/></Col> )}
        </Row>
      </Container>
    </SiteSection>
  );
}

export default Portfolio;