import React from 'react';
import CardOne from "../../Molecules/CardOne";
import {Container, Row, Col} from '../../Atoms/greeds'
import SectionHeader from "../../Molecules/SectionHeader";
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import * as styles from './benefits.module.scss'

const data = [

  {
    badge:"Гарантии",
    name:"Договор и отзывы",
    desc:"С каждым клиентом мы составляем договор где прописываем все аспекты работы. Если договора мало, дадим пообщаться с нашими клиентами и партнерами"
  },
  {
    badge:"Стоимость",
    name:"Подстроимся под вас",
    desc:"У нас есть несколько моделей оплаты проекта, не обязательно оплачивать весь проект одной суммой. Если они вам не подойдут постараемся придумать новый"
  },
  {
    badge:"Время",
    name:"Соблюдение сроков",
    desc:"Вы получите сайт вовремя, потому что мы не разбрасываемся силами команды на 10 проектов одновременно."
  },
  {
    badge:"Навыки",
    name:"Большой опыт",
    desc:"Маркетологи и разработчики в нашей команде работали на такие компании как Яндекс, Coca-Cola, ВТБ, Тинькофф Банк"
  },
  {
    badge:"Визуал",
    name:"Соответствуем трендам",
    desc:"Ваш сайт будет современным и красивым. Как на экранах компьютера, так и на дисплее мобильного телефона"
  },
  {
    badge:"ША**ОН",
    name:"Только уникальность",
    desc:"Считаем что каждый бизнес уникален! Всегда подстраиваем сайт под аналитику вашего направления. Шаблоны используем ТОЛЬКО по желанию клиента."
  },


];

function Benefits(props) {
  return (
    <SiteSection>
      <Container>
          <SectionHeader
            h2
            bigText="Почему нам доверяют"
          >Помогаем искать точки роста и увеличения доходов, используя интернет маркетинг</SectionHeader>

        <Row className={styles.wrapper}>
          {data.map((i,k) => {
            return (
              <Col sm={6} lg={4} key={k}>
                <CardOne Badge={i.badge} Name={i.name} Desk={i.desc} index={k}/>
              </Col>
            )
          })}
        </Row>
      </Container>
    </SiteSection>
  );
}

export default Benefits;